/* @import "/src/fonts/stylesheet.css"; */
/* @import "../fonts/icomoon/style.css"; */
* {
	padding: 0px;
	margin: 0px;
	border: 0px;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

aside,
nav,
footer,
header,
section {
	display: block;
}

html,
body {
	height: 100%;
	min-width: 320px;
}

body {
	line-height: 1;
	font-family: "Gluten", cursive;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
	font-family: "Gluten", cursive;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
	background: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}

html.lock,
body._lock {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
}

body {
	background: #DFA358;
}

._ibg {
	position: relative;
}

._ibg img {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	-o-object-fit: cover;
	object-fit: cover;
}

body.ie ._ibg {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

body.ie ._ibg img {
	width: 0;
	height: 0;
	opacity: 0;
	visibility: hidden;
}

.wrapper {
	min-height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	overflow: hidden;
}

.wrapper>main {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.wrapper>* {
	min-width: 0;
}

._container {
	max-width: 1620px;
	margin: 0 auto;
	padding: 0 30px;
}

@media (max-width: 480px) {
	._container {
		padding: 0 15px;
	}
}

.title {
	font-family: var(--second-family);
	font-weight: 400;
	font-size: 72px;
	color: #fff;
	text-transform: uppercase;
}

@media (max-width: 1919px) {
	.title {
		font-size: calc(35px + 40 * ((100vw - 320px) / 1600));
	}
}

.text25 {
	font-weight: 300;
	font-size: 25px;
	line-height: 1.2;
	color: #fff;
}

@media (max-width: 1919px) {
	.text25 {
		font-size: calc(14px + 9 * ((100vw - 320px) / 1600));
	}
}

.text30 {
	font-weight: 400;
	font-size: 18px;
	line-height: 31px;
	color: #fff;
}

@media (max-width: 1919px) {
	.text30 {
		font-size: calc(18px + 12 * ((100vw - 320px) / 1600));
	}
}

.btn {
	position: relative;
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 40px;
	border: 3px solid #4BA52F;
	border-radius: 60px;
	height: 65px;
	color: #fff;
	text-align: center;
	font-weight: 400;
	font-size: 25px;
	text-transform: uppercase;
	background: #4BA52F;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
	.btn:hover {
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		border: 3px solid #fff;
		background: #fff;
		color: black;
	}
}

@media (min-width: 1024px) and (max-width: 700px) {
	.btn:hover {
		border: 2px solid #fff;
	}
}

@media (max-width: 1024px) {
	.btn {
		height: 40px;
		border-radius: 70px;
		padding: 10px 15px;
		font-size: 16px;
	}
}

@media (max-width: 700px) {
	.btn {
		border: 2px solid #4BA52F;
	}
}

.icons {
	padding: 10px 18px;
	/* border: 3px solid #fff; */
	border-radius: 22px;
	height: 57px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.icons a::before {
	color: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.icons a {
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.icons a:hover {
	transform: translate(0px, -5px);
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
	.icons a:hover::before {
		color: #885d4a;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

.icons .icon-x {
	font-size: 26px;
	color: white;
	line-height: 1px;
}


@media (max-width: 1024px) {
	.icons .icon-x {
		font-size: 19px;
	}
}

.icons .icon-tg {
	font-size: 28px;
	color: white;
	line-height: 1px;
}


@media (max-width: 1024px) {
	.icons .icon-tg {
		font-size: 20px;
	}
}

.icons .icon-i {
	font-size: 30px;
	color: white;
	line-height: 1px;
}

@media (max-width: 1024px) {
	.icons .icon-i {
		font-size: 20px;
	}
}

.icons>*:not(:last-child) {
	margin: 0px 20px 0px 0px;
}

@media (max-width: 1024px) {
	.icons>*:not(:last-child) {
		margin: 0px 10px 0px 0px;
	}
}

@media (max-width: 1024px) {
	.icons {
		/* height: 45px; */
		border-radius: 12px;
	}
}



.header {
	position: relative;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	padding: 25px 0px;
}

@media (max-width: 820px) {
	.header {
		padding: 20px 0px;
	}
}

@media (max-width: 480px) {
	.header {
		padding: 10px 0px;
	}
}

.header__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.header__logo {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 244px;
	flex: 0 0 244px;
	position: relative;
	z-index: 5;
}

.header__logo img {
	width: 100%;
}

@media (max-width: 1024px) {
	.header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 150px;
		flex: 0 0 150px;
	}
}

@media (max-width: 480px) {
	.header__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 130px;
		flex: 0 1 130px;
		margin: 0px 20px 0px 0px;
	}
}

.menu__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.menu__list li {
	margin: 0px 20px 0px 0px;
}

.menu__list li:last-child {
	margin: 0;
}

@media (max-width: 1024px) {
	.menu__list {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.menu__list li {
		margin: 0px 0px 30px 0px;
	}
}

.menu__link {
	font-size: 16px;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
	.menu__link:hover {
		color: #E00000;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

.menu__body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}

@media (max-width: 1024px) {
	.menu__body {
		position: fixed;
		top: 0;
		right: -100%;
		width: 100%;
		height: 100vh;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		background: #fff;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
		padding: 30px 40px;
		overflow: auto;
	}

	.menu__body._active {
		right: 0;
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 1024px) and (max-width: 1024px) {
	.menu__body {
		padding: 125px 40px 75px 40px;
	}
}

@media (max-width: 1024px) and (max-width: 480px) {
	.menu__body {
		padding: 100px 20px;
	}
}

.icon-menu {
	display: none;
}

@media (max-width: 1024px) {
	.icon-menu {
		display: block;
		position: absolute;
		top: 50%;
		right: 30px;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);
		width: 30px;
		height: 18px;
		cursor: pointer;
		z-index: 5;
	}

	.icon-menu span {
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		top: calc(50% - 1px);
		left: 0px;
		position: absolute;
		width: 100%;
		height: 2px;
		background: #000;
	}

	.icon-menu span:first-child {
		top: 0px;
	}

	.icon-menu span:last-child {
		top: auto;
		bottom: 0px;
	}

	.icon-menu._active span {
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	}

	.icon-menu._active span:first-child {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
		top: calc(50% - 1px);
	}

	.icon-menu._active span:last-child {
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		bottom: calc(50% - 1px);
	}
}

@media (max-width: 480px) {
	.icon-menu {
		right: 15px;
	}
}

@media (max-width: 700px) {
	.header__icons {
		margin: 0px 0px 20px 0px;
	}
}

@media (max-width: 700px) {
	.header__icons {
		width: 240px;
	}
}

.header__btn {
	position: relative;
	cursor: pointer;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 10px 30px;
	border: 3px solid #000;
	border-radius: 60px;
	height: 57px;
	color: #000;
	text-align: center;
	font-weight: 400;
	font-size: 25px;
	background: #fff;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
	.header__btn:hover {
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
		background: #4BA52F;
	}
}

@media (max-width: 1024px) {
	.header__btn {
		height: 40px;
		border-radius: 12px;
		padding: 10px 15px;
		font-size: 16px;
	}
}

@media (max-width: 700px) {
	.header__btn {
		border: 2px solid #fff;
	}
}

@-webkit-keyframes zoom {
	0% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}
}

@keyframes zoom {
	0% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}

	50% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	100% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}
}

@-webkit-keyframes rot {
	0% {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes rot {
	0% {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes tb {
	0% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}

	50% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}

	100% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}
}

@keyframes tb {
	0% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}

	50% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}

	100% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}
}

@media (max-width: 480px) {
	@-webkit-keyframes tb {
		0% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}

		50% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}

		100% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}
	}

	@keyframes tb {
		0% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}

		50% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}

		100% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}
	}
}

@-webkit-keyframes tb1 {
	0% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}

	50% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}

	100% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}
}

@keyframes tb1 {
	0% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}

	50% {
		-webkit-transform: translate(0, 0px);
		transform: translate(0, 0px);
	}

	100% {
		-webkit-transform: translate(0, -20px);
		transform: translate(0, -20px);
	}
}

@media (max-width: 480px) {
	@-webkit-keyframes tb1 {
		0% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}

		50% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}

		100% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}
	}

	@keyframes tb1 {
		0% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}

		50% {
			-webkit-transform: translate(0, 0px);
			transform: translate(0, 0px);
		}

		100% {
			-webkit-transform: translate(0, -10px);
			transform: translate(0, -10px);
		}
	}
}

@-webkit-keyframes lr {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}

	50% {
		-webkit-transform: translate(30px, 0px);
		transform: translate(30px, 0px);
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@keyframes lr {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}

	50% {
		-webkit-transform: translate(30px, 0px);
		transform: translate(30px, 0px);
	}

	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}

@media (max-width: 480px) {
	.hero {
		padding: 20px 0px 0px 0px;
	}
}

.hero__title {
	position: relative;
	font-size: 173px;
	letter-spacing: 15px;
	text-align: center;
	display: inline-block;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.hero__title img {
	position: absolute;
	top: 0;
	left: 270px;
	width: 70px;
}

@media (max-width: 1919px) {
	.hero__title img {
		left: calc(75px + 195 * ((100vw - 320px) / 1600));
		width: calc(17px + 53 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1919px) {
	.hero__title {
		font-size: calc(44px + 129 * ((100vw - 320px) / 1600));
		letter-spacing: calc(5px + 10 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 700px) {
	.hero__title {
		margin: 0px 0px 20px 0px;
	}
}

.hero__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media (max-width: 700px) {
	.hero__row {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.hero__img1 {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 580px;
	flex: 0 1 580px;
	position: relative;
	left: -150px;
	-webkit-animation: tb1 3s infinite linear;
	animation: tb1 3s infinite linear;
	text-align: right;
	display: flex;
	align-items: end;
	justify-content: end;
}

.hero__img1 img {
	width: 70%;
}

@media (max-width: 1620px) {
	.hero__img1 {
		left: -50px;
	}
}

@media (max-width: 1024px) {
	.hero__img1 {
		left: 0;
	}
}

@media (max-width: 700px) {
	.hero__img1 {
		display: none;
	}
}

.hero__content {
	padding: 40px 20px;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 750px;
	flex: 0 1 750px;
	min-width: 290px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}

@media (max-width: 700px) {
	.hero__content {
		padding: 0;
		min-width: 100%;
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		margin: 0px 0px 20px 0px;
	}
}

.hero__capy {
	font-size: 40px;
	text-align: center;
	color: #fff;
	margin: 0px 0px 20px 0px;
}

@media (max-width: 1919px) {
	.hero__capy {
		font-size: calc(20px + 20 * ((100vw - 320px) / 1600));
	}
}

.hero__text {
	margin: 0px 0px 70px 0px;
}

@media (max-width: 1620px) {
	.hero__text {
		margin: 0px 0px 40px 0px;
	}
}

@media (max-width: 700px) {
	.hero__text {
		margin: 0px 0px 20px 0px;
	}
}

.hero__img2 {
	position: relative;
	z-index: 1;
	right: 0px;
	top: -50px;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 520px;
	flex: 0 1 520px;
}

.hero__img2>img {
	width: 100%;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
}

@media (max-width: 1919px) {
	.hero__img2 {
		top: calc(0px + -50 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1024px) {
	.hero__img2 {
		right: 0;
	}
}

@media (max-width: 700px) {
	.hero__img2 {
		display: none;
	}
}

.hero__img22 {
	position: absolute;
	z-index: -1;
	bottom: -100px;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 120%;
}

.hero__img22 img {
	width: 100%;
}

@media (max-width: 1620px) {
	.hero__img22 {
		bottom: calc(50px + -200 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1024px) {
	.hero__img22 {
		bottom: calc(200px + -500 * ((100vw - 320px) / 1600));
	}
}

.hero__img3 {
	position: relative;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0px -100px;
}

.hero__img3 img {
	width: 1080px;
}

@media (max-width: 1919px) {
	.hero__img3 img {
		width: calc(150px + 930 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1620px) {
	.hero__img3 {
		margin: 0 auto;
		width: 100%;
	}
}

@media (max-width: 700px) {
	.hero__img3 {
		margin: 0px 0px 20px 0px;
	}
}

.hero__img31 {
	position: absolute;
	top: 20%;
	left: 10%;
	width: 300px;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
}

.hero__img31 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.hero__img31 {
		width: calc(50px + 250 * ((100vw - 320px) / 1600));
	}
}

.hero__img32 {
	position: absolute;
	top: 70%;
	left: 0;
	width: 260px;
	-webkit-animation: tb1 3s infinite linear;
	animation: tb1 3s infinite linear;
}

.hero__img32 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.hero__img32 {
		width: calc(40px + 220 * ((100vw - 320px) / 1600));
	}
}

.hero__img33 {
	position: absolute;
	right: 5%;
	bottom: 5%;
	width: 260px;
	-webkit-animation: tb 3s 0.3s infinite linear;
	animation: tb 3s 0.3s infinite linear;
}

.hero__img33 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.hero__img33 {
		width: calc(40px + 220 * ((100vw - 320px) / 1600));
	}
}

.hero__img34 {
	position: absolute;
	z-index: -1;
	top: 200px;
	right: 1100px;
	width: 370px;
	/* opacity: 0;
	visibility: hidden; */
	-webkit-transition: all 1s ease 0.5s;
	-o-transition: all 1s ease 0.5s;
	transition: all 1s ease 0.5s;
}

.hero__img34 img {
	width: 100%;
}

.hero__img34._watcher-view {
	opacity: 1;
	visibility: visible;
	top: 30px;
	right: -30px;
}

@media (max-width: 1919px) {
	.hero__img34._watcher-view {
		top: calc(-20px + 50 * ((100vw - 320px) / 1600));
		right: calc(0px + -30 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1919px) {
	.hero__img34 {
		width: calc(60px + 310 * ((100vw - 320px) / 1600));
		top: calc(50px + 150 * ((100vw - 320px) / 1600));
		right: calc(200px + 900 * ((100vw - 320px) / 1600));
	}
}

.partners {
	position: relative;
	padding: 60px 0px 0px 0px;
}

.partners__dec1 {
	position: absolute;
	top: -110px;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 100%;
	z-index: -1;
}

.partners__dec1 img {
	width: 100%;
}

.partners__title {
	margin: 0px 0px 70px 0px;
	text-align: center;
}

@media (max-width: 700px) {
	.partners__title {
		margin: 0px 0px 30px 0px;
	}
}

.partners__row {
	position: relative;
}

.partners__items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
	/* gap: 20px; */
}

.partners__items a {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	border-radius: 77px;
	/* background: #fcfcfc; */
	/* padding: 20px 15px; */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	overflow: hidden;
	font-family: var(--second-family);
	font-size: 64px;
	color: #757575;
}

.partners__items a img {
	width: 90%;
}

@media (max-width: 700px) {
	.partners__items a img {
		width: 75%;
	}
}

.partners__items a:nth-child(1) {
	-webkit-transform: rotate(-10deg);
	-ms-transform: rotate(-10deg);
	transform: rotate(-10deg);
}

@media (min-width: 1024px) {
	.partners__items a:nth-child(1):hover {
		-webkit-transform: rotate(-10deg) scale(1.1);
		-ms-transform: rotate(-10deg) scale(1.1);
		transform: rotate(-10deg) scale(1.1);
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 700px) {
	.partners__items a:nth-child(1) {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

.partners__items a:nth-child(2) {
	-webkit-transform: rotate(10deg);
	-ms-transform: rotate(10deg);
	transform: rotate(10deg);
}

@media (min-width: 1024px) {
	.partners__items a:nth-child(2):hover {
		-webkit-transform: rotate(10deg) scale(1.1);
		-ms-transform: rotate(10deg) scale(1.1);
		transform: rotate(10deg) scale(1.1);
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 700px) {
	.partners__items a:nth-child(2) {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

.partners__items a:nth-child(3) {
	-webkit-transform: rotate(-10deg);
	-ms-transform: rotate(-10deg);
	transform: rotate(-10deg);
}

@media (min-width: 1024px) {
	.partners__items a:nth-child(3):hover {
		-webkit-transform: rotate(-10deg) scale(1.1);
		-ms-transform: rotate(-10deg) scale(1.1);
		transform: rotate(-10deg) scale(1.1);
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 700px) {
	.partners__items a:nth-child(3) {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

.partners__items a:nth-child(4) {
	-webkit-transform: rotate(10deg);
	-ms-transform: rotate(10deg);
	transform: rotate(10deg);
}

@media (min-width: 1024px) {
	.partners__items a:nth-child(4):hover {
		-webkit-transform: rotate(10deg) scale(1.1);
		-ms-transform: rotate(10deg) scale(1.1);
		transform: rotate(10deg) scale(1.1);
		-webkit-transition: all 0.3s ease 0s;
		-o-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 700px) {
	.partners__items a:nth-child(4) {
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@media (max-width: 1919px) {
	.partners__items a {
		font-size: calc(40px + 24 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 820px) {
	.partners__items a {
		padding: 15px;
	}
}

@media (max-width: 700px) {
	.partners__items a {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 280px;
		flex: 0 1 280px;
		height: 90px;
	}
}

@media (max-width: 700px) {
	.partners__items {
		gap: 20px;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
}

.profit {
	padding: 100px 0px 0px 0px;
}

.profit__title {
	text-align: center;
	margin: 0px 0px 0px 0px;
}

.profit__row {
	position: relative;
}

@media (max-width: 1620px) {
	.profit__row {
		margin: 0px -30px;
	}
}

.profit__img1 {
	position: absolute;
	z-index: 1;
	left: -450px;
	top: -30px;
	width: 490px;
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
	/* opacity: 0;
	visibility: hidden; */
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	-webkit-animation: profit__img1 3s infinite linear;
	animation: profit__img1 3s infinite linear;
}

.profit__img1._watcher-view {
	left: -300px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.5s ease 0.3s;
	-o-transition: all 0.5s ease 0.3s;
	transition: all 0.5s ease 0.3s;
}

@media (max-width: 1919px) {
	.profit__img1._watcher-view {
		left: calc(200px + -500 * ((100vw - 320px) / 1600));
	}
}

.profit__img1 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.profit__img1 {
		width: calc(100px + 390 * ((100vw - 320px) / 1600));
		left: calc(100px + -400 * ((100vw - 320px) / 1600));
		top: calc(330px + -360 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1200px) {
	.profit__img1 {
		display: none;
	}
}

@-webkit-keyframes profit__img1 {
	0% {
		-webkit-transform: scale(-1, 1) translate(0, 0px);
		transform: scale(-1, 1) translate(0, 0px);
	}

	50% {
		-webkit-transform: scale(-1, 1) translate(0, -20px);
		transform: scale(-1, 1) translate(0, -20px);
	}

	100% {
		-webkit-transform: scale(-1, 1) translate(0, 0px);
		transform: scale(-1, 1) translate(0, 0px);
	}
}

@keyframes profit__img1 {
	0% {
		-webkit-transform: scale(-1, 1) translate(0, 0px);
		transform: scale(-1, 1) translate(0, 0px);
	}

	50% {
		-webkit-transform: scale(-1, 1) translate(0, -20px);
		transform: scale(-1, 1) translate(0, -20px);
	}

	100% {
		-webkit-transform: scale(-1, 1) translate(0, 0px);
		transform: scale(-1, 1) translate(0, 0px);
	}
}

.profit__img2 {
	position: absolute;
	z-index: 1;
	right: -550px;
	top: -80px;
	width: 850px;
	/* opacity: 0;
	visibility: hidden; */
	-webkit-animation: tb1 3s infinite linear;
	animation: tb1 3s infinite linear;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.profit__img2._watcher-view {
	right: -450px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.5s ease 0.8s;
	-o-transition: all 0.5s ease 0.8s;
	transition: all 0.5s ease 0.8s;
}

@media (max-width: 1919px) {
	.profit__img2._watcher-view {
		right: calc(400px + -990 * ((100vw - 320px) / 1600));
	}
}

.profit__img2 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.profit__img2 {
		width: calc(-200px + 1050 * ((100vw - 320px) / 1600));
		right: calc(-100px + -350 * ((100vw - 320px) / 1600));
		top: calc(330px + -410 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1200px) {
	.profit__img2 {
		display: none;
	}
}

.roulette {
	position: relative;
	top: 0;
	left: 50%;
	padding: 80px 0px !important;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	overflow: hidden;
	display: flex;
}

.roulette div {
	width: 100%;
	height: 100%;
}

@media (max-width: 480px) {
	.roulette {
		margin: -70px 0px -60px 0;
	}
}

.roulette__list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	left: 50px;
}

/* .roulette__list::before {
	content: "";
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 540px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	width: 400px;
	height: 400px;
	background: url("../public/assets/profit/star.svg") no-repeat;
	background-size: contain;
} */

@media (min-width: 1921px) {
	.roulette__list {
		left: 40px;
	}
}

@media (max-width: 1620px) {
	.roulette__list {
		left: calc(-575px + 795 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 480px) {
	.roulette__list {
		left: calc(-395px + 615 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 480px) {
	.roulette__list {
		-webkit-transform: scale(0.7);
		-ms-transform: scale(0.7);
		transform: scale(0.7);
	}
}

.roulette__btn {
	width: 140px;
	position: relative;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.roulette__prize {
	position: relative;
	min-width: 180px !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	border: 5px solid rgba(255, 255, 255, 0.5);
	border-radius: 64px;
	font-family: var(--third-family);
	font-weight: 400;
	font-size: 70px;
	text-align: center;
	color: #4BA52F;
}

.roulette__prize:nth-child(1) {
	min-width: 130px !important;
	min-height: 70px !important;
	font-size: 25px;
	border-radius: 25px;
	-webkit-filter: blur(6px);
	filter: blur(6px);
}

.roulette__prize:nth-child(2) {
	min-width: 150px !important;
	min-height: 100px !important;
	font-size: 35px;
	border-radius: 35px;
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.roulette__prize:nth-child(3) {
	min-width: 200px !important;
	min-height: 150px !important;
	font-size: 50px;
	border-radius: 50px;
	-webkit-filter: blur(4px);
	filter: blur(4px);
}

.roulette__prize:nth-child(4) {
	min-width: 270px !important;
	min-height: 200px !important;
}

.roulette__prize:nth-child(5) {
	min-width: 200px !important;
	min-height: 150px !important;
	font-size: 50px;
	border-radius: 50px;
	-webkit-filter: blur(4px);
	filter: blur(4px);
}

.roulette__prize:nth-child(6) {
	min-width: 150px !important;
	min-height: 100px !important;
	font-size: 35px;
	border-radius: 35px;
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.roulette__prize:nth-child(7) {
	min-width: 130px !important;
	min-height: 70px !important;
	font-size: 25px;
	border-radius: 25px;
	-webkit-filter: blur(6px);
	filter: blur(6px);
}

.profit__btn {
	margin: 70px 0px 0px 0px;
	background: #fff;
	border: 3px solid #fff;
	color: black;
	position: relative;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}

.profit__btn:hover {
	background: #00C74D;
	border: 3px solid #00C74D;
	color: white;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0)
}



@media (max-width: 1024px) {
	.profit__btn {
		margin: 40px 0px 0px 0px;
	}
}

.about {
	position: relative;
	z-index: 1;
	padding: 200px 0px 0px 0px;
}

@media (max-width: 1024px) {
	.about {
		padding: 60px 0px 0px 0px;
	}
}

.about__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
}

@media (max-width: 700px) {
	.about__row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

.about__left {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 700px;
	flex: 0 1 700px;
	position: relative;
}

@media (max-width: 820px) {
	.about__left {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 600px;
		flex: 0 1 600px;
	}
}

@media (max-width: 700px) {
	.about__left {
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		margin: 0px 0px 30px 0px;
	}
}

.about__title {
	margin: 0px 0px 35px 0px;
}

@media (max-width: 700px) {
	.about__title {
		text-align: center;
	}
}

.about__text {
	border-radius: 50px;
	padding: 0px;
	background: transparent;
	color: #fff;
	text-align: left;
}

@media (max-width: 1919px) {
	.about__text {
		border-radius: calc(25px + 25 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1300px) {
	.about__text {
		padding: 30px;
	}
}

@media (max-width: 480px) {
	.about__text {
		padding: 20px;
	}
}

.about__img {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 600px;
	flex: 1 1 600px;
	margin: 0px 0px 0px -50px;
	position: relative;
	right: -30%;
	text-align: center;
	/* opacity: 0;
	visibility: hidden; */
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
}

.about__img._watcher-view {
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.5s ease 0.5s;
	-o-transition: all 0.5s ease 0.5s;
	transition: all 0.5s ease 0.5s;
	right: 0;
}

.about__img img {
	width: 70%;
}

@media (max-width: 700px) {
	.about__img {
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		margin: 0px -70px 0px 0px;
		right: 0;
	}
}

.tokenomics {
	padding: 60px 0px 0px 0px;
}

.tokenomics__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media (max-width: 700px) {
	.tokenomics__row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}
}

.tokenomics__left {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 740px;
	flex: 0 1 740px;
	min-width: 412px;
	margin: 0px 30px 0px 0px;
}

@media (max-width: 700px) {
	.tokenomics__left {
		min-width: 0;
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		margin: 0px 0px 30px 0px;
	}
}

.tokenomics__title {
	margin: 0px 0px 50px 0px;
}

@media (max-width: 1300px) {
	.tokenomics__title {
		margin: 0px 0px 20px 0px;
	}
}

.tokenomics__info {
	margin: 0px 0px 50px 0px;
}

.tokenomics__info>*:not(:last-child) {
	margin: 0px 0px 30px 0px;
}

@media (max-width: 700px) {
	.tokenomics__info>*:not(:last-child) {
		margin: 0px 0px 15px 0px;
	}
}

@media (max-width: 700px) {
	.tokenomics__info {
		margin: 0px 0px 30px 0px;
	}
}

.tokenomics__values {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	gap: 20px;
}

.tokenomics__values li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: left;
	flex: 0 1 30%;
	font-size: 22px;
	color: black;
	text-transform: uppercase;
}

.tokenomics__values li span {
	margin: 0px 18px 0px 0px;
	flex: 0 0 30px;
	width: 30px;
	height: 30px;
	border: 5px solid #fff;
	border-radius: 22px;

}

@media (max-width: 480px) {
	.tokenomics__values li span {
		border-radius: 30px;
		border: 3px solid #fff;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 30px;
		flex: 0 0 30px;
		width: 30px;
		height: 30px;
	}
}

.tokenomics__img {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 680px;
	flex: 0 1 680px;
	position: relative;
	/* opacity: 0;
	visibility: hidden; */
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	right: -20%;
}

.tokenomics__img._watcher-view {
	right: 0;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.5s ease 0.5s;
	-o-transition: all 0.5s ease 0.5s;
	transition: all 0.5s ease 0.5s;
}

.tokenomics__img img {
	width: 100%;
}

@media (max-width: 700px) {
	.tokenomics__img {
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		width: 180px;
		right: 0;
	}
}

.tokenomics__copyblock {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	gap: 20px;
	background: white;
	border-radius: 60px;
	color: black;
	padding: 10px 20px;
	border: 2px solid #000;
	width: 100%;
	max-width: 570px;
}

.tokenomics__copyblock input {
	background: none;
	width: 800px;
}

.text {
	font-size: 15px;
	flex: 1;
}

@media (max-width: 1919px) {
	.tokenomics__copyblock input {
		width: calc(220px + 480 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1300px) {
	.tokenomics__copyblock input {
		width: 100%;
	}
}

@media (max-width: 1300px) {
	.tokenomics__copyblock {
		/* -webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column; */
		margin: 0px 0px 20px 0px;
	}

	.tokenomics__copyblock>*:not(:last-child) {
		margin: 0px 0px 00px 0px;
	}
}

.tokenomics__ca {
	font-family: var(--third-family);
	font-weight: 400;
	font-size: 22px;
	color: #fff;
}

@media (max-width: 1919px) {
	.tokenomics__ca {
		font-size: calc(12px + 10 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 700px) {
	.tokenomics__ca {
		text-align: center;
	}

	.tokenomics__copyblock {
		gap: 10px;
		padding: 5px 12px;
	}
}

@media (max-width: 360px) {
	.tokenomics__ca {
		font-size: 11px;
	}

}

.copy-btn {
	width: 120px;
	color: #fff;
	border-radius: 50px;
	background: #00C74D;
	padding: 6px 10px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (min-width: 1024px) {
	.copy-btn:hover {
		background: #DFA358;
		color: #fff;
	}
}

.howto {
	padding: 60px 0px 0px 0px;
}

@media (max-width: 700px) {
	.howto {
		padding: 60px 0px 0px 0px;
	}
}

.howto__title {
	text-align: center;
	margin-bottom: 70px;
}

.howto__row {
	position: relative;
}

.howto__dec1 {
	position: absolute;
	top: 30%;
	left: -200px;
	width: 400px;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
}

.howto__dec1 img {
	width: 100%;
}

@media (max-width: 1620px) {
	.howto__dec1 {
		display: none;
	}
}

.howto__dec2 {
	position: absolute;
	top: -370px;
	left: -100px;
	width: 340px;
	-webkit-animation: tb 3s infinite linear;
	animation: tb 3s infinite linear;
}

.howto__dec2 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.howto__dec2 {
		width: calc(100px + 240 * ((100vw - 320px) / 1600));
		left: calc(20px + -120 * ((100vw - 320px) / 1600));
		top: calc(-150px + -220 * ((100vw - 320px) / 1600));
	}
}

.howto__dec3 {
	position: absolute;
	top: 0;
	right: -160px;
	width: 400px;
	-webkit-animation: tb1 3s infinite linear;
	animation: tb1 3s infinite linear;
}

.howto__dec3 img {
	width: 100%;
}

@media (max-width: 1620px) {
	.howto__dec3 {
		display: none;
	}
}

.howto__items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 1200px;
	margin: 0 auto;
}

.howto__items>*:not(:last-child) {
	margin: 0px 0px 68px 0px;
}

@media (max-width: 480px) {
	.howto__items>*:not(:last-child) {
		margin: 0px 0px 30px 0px;
	}
}

.howto__item {
	padding: 35px;
	max-width: 50%;
	color: #000;
	position: relative;
	z-index: 1;
}

.howto__item::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	border-radius: 50px;
}

@media (max-width: 1919px) {
	.howto__item::before {
		border-radius: calc(25px + 25 * ((100vw - 320px) / 1600));
	}
}

.howto__item p {
	position: relative;
}

.howto__item:nth-child(even) {
	-ms-flex-item-align: end;
	-ms-grid-row-align: end;
	align-self: end;
}

.howto__item {
	max-width: 510px;
}

/* .howto__item:nth-child(2) {
	max-width: 485px;
}
.howto__item:nth-child(3) {
	max-width: 505px;
} */
/* .howto__item:nth-child(4) {
	max-width: 467px;
	left: -50px;
} */
/* @media (max-width: 1800px) {
	.howto__item:nth-child(4) {
		left: 0;
	}
} */
@media (max-width: 1024px) {
	.howto__item {
		width: 100%;
	}
}

@media (max-width: 820px) {
	.howto__item {
		padding: 20px;
	}
}

.howto__img1 {
	position: absolute;
	bottom: 80%;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 370px;
	-webkit-animation: howto__img1 3s infinite linear;
	animation: howto__img1 3s infinite linear;
	z-index: -1;
}

.howto__img1 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.howto__img1 {
		width: calc(180px + 190 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 820px) {
	.howto__img1 {
		bottom: 95%;
	}
}

@-webkit-keyframes howto__img1 {
	0% {
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}

	50% {
		-webkit-transform: translate(-50%, 10px);
		transform: translate(-50%, 10px);
	}

	100% {
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
}

@keyframes howto__img1 {
	0% {
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}

	50% {
		-webkit-transform: translate(-50%, 10px);
		transform: translate(-50%, 10px);
	}

	100% {
		-webkit-transform: translate(-50%, 0);
		transform: translate(-50%, 0);
	}
}

.howto__img2 {
	position: absolute;
	z-index: -10;
	bottom: 80%;
	left: 50%;
	-webkit-transform: translate(-50%, 0);
	-ms-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
	width: 410px;
	-webkit-animation: howto__img1 3s infinite linear;
	animation: howto__img1 3s infinite linear;
}

.howto__img2 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.howto__img2 {
		width: calc(200px + 210 * ((100vw - 320px) / 1600));
	}
}

.howto__img3 {
	position: absolute;
	top: 80%;
	right: 95%;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	width: 630px;
	-webkit-animation: howto__img3 3s infinite linear;
	animation: howto__img3 3s infinite linear;
}

.howto__img3 img {
	width: 100%;
}

@media (max-width: 1919px) {
	.howto__img3 {
		width: calc(180px + 450 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 480px) {
	.howto__img3 {
		top: 50%;
	}
}

@-webkit-keyframes howto__img3 {
	0% {
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}

	50% {
		-webkit-transform: translate(0, -52%);
		transform: translate(0, -52%);
	}

	100% {
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}
}

@keyframes howto__img3 {
	0% {
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}

	50% {
		-webkit-transform: translate(0, -52%);
		transform: translate(0, -52%);
	}

	100% {
		-webkit-transform: translate(0, -50%);
		transform: translate(0, -50%);
	}
}

.faq {
	position: relative;
	z-index: 1;
	padding: 0px 0px 0px 0px;
}

@media (max-width: 820px) {
	.faq {
		padding: 150px 0px 0px 0px;
	}
}

@media (max-width: 480px) {
	.faq {
		padding: 60px 0px 0px 0px;
	}
}

.faq__title {
	margin: 0px 0px 40px 0px;
	text-align: center;
}

@media (max-width: 480px) {
	.faq__title {
		margin: 0px 0px 30px 0px;
	}
}

.faq__row {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
}

.block._init {
	padding: 30px;
	border-radius: 30px;
	background: #D69B52;
	border: 2px solid #fff;
}

.faq__row .block>*:not(:last-child) {
	margin: 0px 0px 20px 0px;
}

.faq__row .block__item {
	border-radius: 32px;
	padding: 20px 30px;
}

@media (max-width: 700px) {
	.faq__row .block__item {
		padding: 20px;
	}
}

@media (max-width: 480px) {
	.faq__row .block__item {
		border-radius: 20px;
	}
}

.faq__row .block__title {
	text-align: left;
	width: 100%;
	font-family: var(--font3);
	font-weight: 400;
	font-size: 40px;
	color: #fff;
	position: relative;
	padding: 0px 40px 0px 0px;
}

.faq__row .block__title::before {
	content: "";
	position: absolute;
	top: 0px;
	right: 0px;
	width: 35px;
	height: 35px;
	background: url("../public/assets/icons/select.svg") no-repeat;
	background-size: contain;
	background-position: center;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

@media (max-width: 480px) {
	.faq__row .block__title::before {
		width: 25px;
		height: 25px;
	}
}

.faq__row .block__title._active::before {
	-ms-transform: rotate(-180deg);
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

/* @media (max-width: 1919px) {
	.faq__row .block__title {
		font-size: calc(20px + 10 * ((100vw - 320px) / 1600));
	}
} */
.faq__row .block__text {
	padding: 30px 0px 0px 0px;
	color: #000;
}

@media (max-width: 480px) {
	.faq__row .block__text {
		padding: 15px 0px 0px 0px;
	}
}

@media (max-width: 700px) {
	.faq__row {
		width: 100%;
	}
}

.faq__img {
	position: absolute;
	bottom: 0;
	left: 800px;
	width: 1200px;
	/* opacity: 0;
	visibility: hidden; */
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
}

.faq__img._watcher-view {
	left: 600px;
	opacity: 1;
	visibility: visible;
	-webkit-transition: all 0.5s ease 0.7s;
	-o-transition: all 0.5s ease 0.7s;
	transition: all 0.5s ease 0.7s;
}

@media (max-width: 1919px) {
	.faq__img._watcher-view {
		left: calc(350px + 350 * ((100vw - 320px) / 1600));
	}
}

.faq__img img {
	width: 100%;
}

@media (max-width: 1900px) {
	.faq__img {
		width: 700px;
		left: calc(450px + 350 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 1200px) {
	.faq__img {
		width: calc(50px + 850 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 700px) {
	.faq__img {
		display: none;
	}

	.header__icons {
		display: none;
	}
}

.footer {
	padding: 60px 0px 40px 0px;
}

@media (max-width: 1024px) {
	.footer {
		padding: 50px 0px 40px 0px;
	}
}

@media (max-width: 480px) {
	.footer {
		padding: 30px 0px 20px 0px;
	}
}

.footer p {
	font-size: 18px;
	line-height: 24px;
}

.footer__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 20px;
}

.footer__row>*:not(:last-child) {
	margin: 0px 20px 0px 0px;
}

@media (max-width: 1024px) {
	.footer__row>*:not(:last-child) {
		margin: 0px 0px 20px 0px;
	}
}

@media (max-width: 1024px) {
	.footer__row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

.footer__logo {
	-webkit-box-flex: 0;
	-ms-flex: 0 1 244px;
	flex: 0 1 244px;
}

.footer__logo img {
	width: 100%;
}

@media (max-width: 1024px) {
	.footer__logo {
		-webkit-box-flex: 0;
		-ms-flex: 0;
		flex: 0;
		max-width: 150px;
	}
}

.footer__ca {
	font-family: var(--third-family);
	font-weight: 400;
	font-size: 24px;
	color: #000;
}

@media (max-width: 1919px) {
	.footer__ca {
		font-size: calc(12px + 13 * ((100vw - 320px) / 1600));
	}
}

@media (max-width: 360px) {
	.footer__ca {
		font-size: 11px;
	}
}

.sm-icon {
	display: none;
}

.tokenomics__info p {
	margin: 0 !important;
	font-size: 20px;
}

.item-none {
	padding: 0 !important;
}

.item-none::before {
	content: none;
}

.position-relative {
	position: relative;
}

.howto__item {
	padding: 30px;
	z-index: 1;
	font-size: 22px;
}

.box-1 {
	border-radius: 30px;
}

.box-2 {
	position: relative;
	right: -120px;
	top: -20px;
}

.box-1::before {
	border-radius: 30px;
	background: #FBF2E5;
}

.box-2::before {
	border-radius: 30px;
	border: 2px solid black;
}

.howto__items .position-relative {
	max-width: 560px;
	width: 100%;
}

.howto__items .position-relative.ml-auto {
	margin-left: auto;
}

.howto__items .position-relative.ml-auto .box-2 {
	right: 0;
	left: -120px;
}

.footer-bg {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}

.footer-bg img {
	width: 100%;
}

.dec2 {
	display: none;
}

@media (max-width: 700px) {
	.dec2 {
		display: block;
	}

	.dec1 {
		display: none;
	}

	.partners__dec1 {
		top: -70px;
	}

	.sm-icon,
	.sm-icon .header__icons {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.hero__btn.sm-btn {
		display: none;
	}

	.howto__item {
		padding: 20px;
		font-size: 16px;
		max-width: 320px;
	}

	.howto__items {
		gap: 230px;
	}

	.howto__item p {
		font-size: 16px;
		line-height: 20px;
	}

	.box-1::before,
	.box-2::before {
		border-radius: 20px;
	}

	.box-2 {
		right: -40px;
		top: -16px;
	}

	.howto__items .position-relative.ml-auto .box-2 {
		right: 0;
		left: 0px;
	}

	.ml-auto .box-1 {
		margin-left: auto;
	}

	.howto__title {
		margin-bottom: 20px;
	}

	.tokenomics__row {
		flex-direction: column-reverse;
	}

	.tokenomics__img {
		width: 80%;
	}

	.about__text {
		padding: 0px !important;
	}

	.about__title {
		margin: 0px 0px 20px 0px;
	}

	.hero__capy {
		font-size: 36px;
		line-height: 40px;
	}

	.text25 {
		font-size: 18px
	}

	.hero__btn {
		margin-bottom: 20px;
	}

	.profit {
		padding: 30px 0px 0px 0px;
	}

	.text {
		font-size: 15px;
	}

	.copy-btn {
		width: 86px;
	}

	.tokenomics__values li {
		flex: 0 1 45%;
		font-size: 16px;
	}

	.tokenomics__values {
		justify-content: center;
	}

	.tokenomics__values li:last-child {
		flex: 1 1 100%;
		display: flex;
		justify-content: center;

	}

	.faq__row .block__title {
		font-size: 26px;
	}

	.block._init {
		padding: 10px
	}
	.partners {
		padding: 25px 0px 0px 0px;
	}
	.title {
		font-size: calc(38px + 40*((100vw - 320px) / 1600));
	}
}

.center {
	text-align: center;
	display: flex;
    align-items: center;
    justify-content: center;
}

.opacity-0 {
	opacity: 0;
}

li {
	list-style: none;
	display: inline-block;
	float: left;
}

.window {
	overflow: hidden;
	position: relative;
	width: 25000px;
	height: 230px;
	right: 0px;
	display: flex;
}

.wraper {
	position: relative;
	margin: auto;
	margin-top: 70px;
	width: 100%;
	max-width: 700px;
	overflow-x: hidden;
	overflow-y: hidden;
	/* border: 4px solid #1a96b7; */
	border-radius: 2px;
	margin-bottom: 40px;
}

.list {
	position: relative;
	margin-left: 230;
	display: flex;
	align-items: center;
}

.list li {
	border: 4px solid transparent;
	max-width: 120px;
}

.list li img {
	width: 100%;
}

.arrowup {
	position: absolute;
	bottom: 0;
	right: 0px;
	z-index: 1;
	width: 0;
	height: 0;
	border-bottom: 20px solid #1a96bf;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	left: 0;
	margin: 0 auto;
}

.arrowdown {
	position: absolute;
	top: 0;
	right: 0px;
	z-index: 1;
	width: 0;
	height: 0;
	border-top: 20px solid #1a96bf;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	left: 0;
	margin: 0 auto;
}

@media (max-width: 767px) {
	.list {
		margin-left: -112px;
	}

	.window {
		height: 140px;
	}
}

.slider{
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 30px;
	margin-bottom: 40px;
	margin-top: 30px;
}
.slide {
		position: relative;
		min-width: 180px !important;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: #fff;
		border: 0px solid rgba(255, 255, 255, 0.5);
		border-radius: 64px;
		font-family: var(--third-family);
		font-weight: 400;
		font-size: 70px;
		text-align: center;
		color: #4BA52F;
}

.slide:nth-child(5) {
    min-width: 150px !important;
    min-height: 100px !important;
    font-size: 35px;
    border-radius: 35px;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}
.slide:nth-child(4) {
    min-width: 200px !important;
    min-height: 150px !important;
    font-size: 50px;
    border-radius: 50px;
    -webkit-filter: blur(4px);
    filter: blur(4px);
}
.slide:nth-child(3) {
    min-width: 270px !important;
    min-height: 200px !important;
}
.slide:nth-child(2) {
    min-width: 200px !important;
    min-height: 150px !important;
    font-size: 50px;
    border-radius: 50px;
    -webkit-filter: blur(4px);
    filter: blur(4px);
}
.slide:nth-child(1) {
    min-width: 150px !important;
    min-height: 100px !important;
    font-size: 35px;
    border-radius: 35px;
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.sc-gsTCUz.bhdLno{
	margin: 50px auto;
}
